import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Container, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import CommonTable from "../../components/tables/CommonTable";
import { Grid } from "react-feather";
import * as queryString from "query-string";
import { NavLink, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import axios from "axios";
import api from "../../api";
import DeviceForm from "../../components/DeviceForm";
import DeviceDetail from "../../components/deviceInfo/DeviceDetail";
import NotyfContext from "../../contexts/NotyfContext";

let source1 = null;

const QrCodes = () => {
    const [resultCount, setResultCount] = useState(0);
    const [showModal, setShow] = useState(false);
    const [showEditModal, setShowEdit] = useState(null);
    const [customerOption, setCustomer] = useState([]);
    const [deviceOption, setDevice] = useState([]);
    const [name, setName] = useState(null);

    const { user } = useAuth();
    const location = useLocation();
    const { assignment } = queryString.parse(location.search);
    const curTime = useRef(moment());
    const childRef = useRef();
    const notify = useContext(NotyfContext);

    const columns = [
        {
            Header: "#Serial",
            accessor: "serial",
            Cell: (cell) => {
                if (user?.role === 'Root') {
                    return <NavLink to={{ pathname: "/qrcodes/edit", search: `?id=${cell.row.original.id}` }}>{cell.value}</NavLink>;
                } else {
                    return <span onClick={() => setShowEdit(cell.row.original)} className="text-primary cursor-pointer">{cell.value}</span>
                }
            }
        },
        {
            Header: "Last Seen",
            accessor: "lastReport",
            type: "dateFromNow"
        },
        {
            Header: "Customer",
            accessor: "customerName",
            show: user?.role === "Root",
            disableSortBy: true,
            Cell: cell => (<NavLink
                to={{ pathname: `/customers/edit`, search: `?id=${cell.row?.original?.customerId}` }}>{cell.value}</NavLink>)
        },
        {
            Header: "Linked",
            accessor: "customerId",
            type: "linkStatus",
            disableSortBy: true
        }
    ];

    useEffect(() => {
        if (user?.role === 'Root') {
            getCustomers();
            setDevice([]);
        }
    }, [user])

    useEffect(() => {
        return () => {
            if (source1) {
                source1.cancel('request canceled');
            }
        }
    }, []);

    const getCustomers = () => {
        if (source1) {
            source1.cancel('request canceled');
        }
        source1 = axios.CancelToken.source();
        api.get(`customers?page=1&limit=999`, {
            cancelToken: source1.token
        }).then(res => setCustomer(res.data)).catch(() => {
            setCustomer([])
        })
    };

    const getSecAttributes = () => {
        if (assignment && (assignment === "assigned" || assignment === "unassigned")) {
            return ({
                secFilterId: "linked",
                secId: (assignment === "assigned") ? "true" : "false",
            });
        }
        else {
            return ({});
        }
    }

    const updateData = () => {
        childRef.current.updateTable(1);
        setShow(false);
    };

    const onSave = () => {
        let obj = JSON.parse(JSON.stringify(showEditModal))
        obj.deviceName = name;
        api.put(`qrcodes`, obj)
            .then(res => {
                notify.open({
                    type: 'success',
                    message: 'Changes Saved'
                })
                childRef.current.updateTable()
                setShowEdit(null)
            });
    }


    return <>
        <Helmet defer={false} title="QR Codes" />
        <Container fluid className="p-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="h3 mb-0"><Grid className='me-2' />{assignment && ((assignment.toLowerCase() === "assigned") ? "Assigned " : "Unassigned ")}QR Codes {typeof (resultCount) === 'string' && `(${resultCount})`}</h1>
                {user?.role === "Root" &&
                    <Button className="text-nowrap" onClick={() => setShow(true)}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Create New</Button>}
            </div>
            <Card className="bg-white p-3">
                <CommonTable curTime={curTime.current} ref={childRef} setCountParent={setResultCount} apiName="qrcodes"
                    columns={columns} showExport
                    {...getSecAttributes()}
                />
            </Card>
        </Container>
        <Modal show={showModal} onHide={() => setShow(false)}>
            <Modal.Header closeButton><Modal.Title>New QR Code</Modal.Title></Modal.Header>
            <Modal.Body>
                <DeviceForm setAsset={updateData} apiName="qrcodes" customerOption={customerOption} deviceOption={deviceOption} />
            </Modal.Body>
        </Modal>
        <Modal show={showEditModal} onHide={() => setShowEdit(null)}>
            <Modal.Header closeButton>
                <Modal.Title>{showEditModal?.serial}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DeviceDetail onChangeName={(e) => setName(e)} data={showEditModal} type="qrcodes" />
            </Modal.Body>
            <Modal.Footer>
                {user?.editTags && <Button onClick={() => onSave()}>Save</Button>}
                <Button onClick={() => setShowEdit(null)} variant={'secondary'}>Close</Button>
            </Modal.Footer>
        </Modal>
    </>;
}

export default QrCodes;
