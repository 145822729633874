import {
  AlertTriangle,
  Bookmark,
  CheckSquare,
  Clipboard,
  Disc,
  Globe,
  Grid,
  Hash,
  Image,
  Mail,
  Map,
  Monitor,
  Package,
  Radio,
  Smartphone,
  Square,
  Sun,
  Users,
  Wifi,
  XSquare
} from "react-feather";

/* menu items for admin/user role
 * 'type' field is permission name which from api/profile endpoint, if it is false, will not show the current menu
 * 'permission' field is for different user role, permission: 'Admin' means only show this menu to admin user
 */

const trackingSection = [
  {
    href: "/assets",
    icon: Package,
    title: "Assets",
  },
  {
    href: "/archived-assets",
    icon: Package,
    title: "Archived Assets",
    permission: "Admin"
  },
  {
    href: "/people",
    icon: Users,
    title: "People",
  },
  {
    href: "/zones",
    icon: Disc,
    title: "Zones",
  },
  {
    href: "/maps",
    icon: Map,
    title: "Map",
    type: 'showMap'
  },
  {
    href: "/floorplans",
    icon: Image,
    title: "Floorplans",
    type: 'showFloorPlans'
  },
];

const devicesSection = [
  {
    href: "/trackers",
    icon: Globe,
    title: "Trackers",
  },
  {
    href: "/archived-trackers",
    icon: Globe,
    title: "Archived Trackers",
    permission: "Admin",
  },
  {
    href: "/readers",
    icon: Wifi,
    title: "Readers",
  },
  {
    href: "/archived-readers",
    icon: Wifi,
    title: "Archived Readers",
    permission: "Admin",
  },
  {
    href: "/tags",
    icon: Radio,
    title: "Tags",
  },
  {
    href: "/archived-tags",
    icon: Radio,
    title: "Archived Tags",
    permission: "Admin",
  },
  {
    href: "/apps",
    icon: Smartphone,
    title: "Apps",
  },
  {
    href: "/qrcodes",
    icon: Grid,
    title: "QR Codes",
    type: 'showQrCodes',
    permission: 'Admin',
  },
  {
    href: "/solarchargers",
    icon: Sun,
    title: "Solar Chargers",
    type: 'showSolarMonitoring',
    permission: 'Admin',
  }
];

const adminSection = [
  {
    href: "/contactgroups",
    icon: Clipboard,
    title: "Contact Groups",
  },
  {
    href: "/rules",
    icon: AlertTriangle,
    title: "Rules",
  },
  {
    href: "/alerthistory",
    icon: Mail,
    permission: 'Admin',
    title: "Alert History",
  },
  {
    href: "/tasktypes",
    icon: Bookmark,
    title: "Task Types",
    permission: 'Admin',
    type: 'showTasks'
  },
  {
    href: "/hashtags",
    icon: Hash,
    title: "Hashtags",
    permission: 'Admin'
  },
  {
    href: "/accessprofiles",
    icon: Monitor,
    title: "Access Profiles",
    permission: 'Admin'
  }
];

const taskSection = [{
  href: "/scheduledtask",
  icon: Square,
  title: "Scheduled",
  type: 'showTasks'
},{
  href: "/completedtask",
  icon: CheckSquare,
  title: "Completed",
  type: 'showTasks'
},{
  href: "/outstandingtask",
  icon: XSquare,
  title: "Outstanding",
  type: 'showTasks'
}
]

const navItems = [
  {
    title: "Tracking",
    pages: trackingSection,
  },
  {
    title: "Tasks",
    pages: taskSection,
    type: 'showTasks'
  },
  {
    title: "Devices",
    pages: devicesSection,
  },
  {
    title: "Admin",
    pages: adminSection,
  }
];

export default navItems;
