import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../../api";
import { Button, Container, Dropdown, Modal } from "react-bootstrap";
import { Globe } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../tables/CommonTable";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";
import useSrlHelper from "../../hooks/useSrlHelper";

const DeviceTracker = ({ id, getTrackerReady, type, data }) => {
    const [tracker, setTracker] = useState([]);
    const [showModal, setShow] = useState(false);
    const [curId, setCurrent] = useState(null);
    const notify = useContext(NotyfContext);
    const [showWarning, setShowWarning] = useState(false);
    const { user } = useAuth();
    const isUnmount = useRef(false);
    const { isSrl } = useSrlHelper();

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 60,
      disableSortBy: true,
      Cell: cell => (
        <Button
          variant={cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "danger" : "success"}
          onClick={() => onOperate(cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "warn" : "edit", cell.value, cell.row.original)}>Link</Button>
      )
    },
    {
      Header: "#Serial",
      accessor: "serial"
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Linked",
      accessor: "elementId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];

  useEffect(() => {
    if (!id) return
    setShow(false)
    setCurrent(false)
    getTracker();
  }, [id]);

  useEffect(() => {
    return () => { isUnmount.current = true }
  }, [])

  const getTracker = () => {
    api.get(`${type}/trackers/${id}`)
      .then(res => {
        if (isUnmount.current) return
        setTracker(res.data);
        getTrackerReady && getTrackerReady([res.data]);
      }).catch(() => {
        if (isUnmount.current) return
        setTracker(null);
        getTrackerReady && getTrackerReady([]);
      });
  };

  const trackerCommand = (deviceId, commandId) => {
    api.post(`trackers/${deviceId}/sendcommand/${commandId}`)
      .then(res => {
        notify.open({
          type: "success",
          message: "Command Sent"
        });
      });
  };

  const trackerLink = () => {
    setShow(true);
  };

  const trackerUnlink = (trackerId) => {
    setCurrent(trackerId);
    setShow(true);
  };

  const onOperate = (name, chooseId, data) => {
    if (tracker) {
      api.post(`trackers/${tracker.id}/unlink/${id}`, { params: { parentId: id, id: tracker.id } })
        .then(res => {
          notify.open({
            type: "success",
            message: "Changes Saved"
          });
          getTracker();
        }).catch(e => {
          notify.open({
            type: "error",
            message: e
          });
        });
      setCurrent(null);
    } else {
      if (name === "edit") {
        api.post(`trackers/${chooseId}/link/${id}`, { params: { parentId: id, id: curId } })
          .then(res => {
            notify.open({
              type: "success",
              message: "Changes Saved"
            });
            getTracker();
          }).catch(e => {
            notify.open({
              type: "error",
              message: e
            });
          });
        setCurrent(null);
      } else if (name === "warn") {
        setShowWarning(data);
      } else if (name === "relink") {
        api.post(`trackers/${chooseId?.id}/unlink/${chooseId?.assetId || chooseId?.personId || chooseId?.zoneId}`)
          .then(res => {
            api.post(`trackers/${chooseId?.id}/link/${id}`, { params: { parentId: id, id: curId } })
              .then(res => {
                notify.open({
                  type: "success",
                  message: "Changes Saved"
                });
                getTracker();
                setShowWarning(null);
              });
          }).catch(e => {
            notify.open({
              type: "error",
              message: e
            });
          });
      }
    }
    setShow(false);
  };

  return <React.Fragment>
    <Container fluid className="p-0 m-0">
      {/* trackers */}
      {
        tracker
          ?
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h5><Globe size={18} /> GPS</h5>
              {
                user?.editTrackers && user?.role !== 'Root' && !(user?.role === "User" && isSrl()) &&
                <Button variant="danger" onClick={() => trackerUnlink(tracker.id)}>Unlink Tracker</Button>
              }
            </div>
            {
              user?.role === 'Root' ? <div className="mt-2">
                <span>Serial: <NavLink to={{ pathname: '/trackers/edit', search: `?id=${tracker.id}` }}>{tracker.serial}</NavLink></span>
              </div> : <div className="mt-2">Serial: {tracker.serial}</div>
            }
            <div>Model: {tracker.model}</div>
            {
              tracker.commands && tracker.commands.length > 0 && user?.role !== 'Root' &&
              <Dropdown>
                <Dropdown.Toggle variant="success">
                  Send Command
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    tracker.commands.map((command, index) => <Dropdown.Item key={index}
                      onClick={() => trackerCommand(tracker.id, command.commandId)}>{command.commandName}</Dropdown.Item>)
                  }
                </Dropdown.Menu>
              </Dropdown>
            }
          </>
          :
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h5>
                <Globe size={18} /> GPS
              </h5>
                {user?.editTrackers && user?.role !== 'Root' && !(user?.role === "User" && isSrl()) &&
                <Button variant="primary" onClick={() => trackerLink()}>Link a Tracker</Button>}
            </div>
            <div className="mt-2">TracLogik's ruggedized, covert, personal and fleet GPS devices are used to track
              transient vehicles, assets or personnel as they move from place to place, virtually anywhere in the world.
            </div>
          </>
      }

    </Container>
    <hr />
    <Modal show={showModal} size={curId ? `` : "lg"} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{`${curId ? "Unlink a" : "Link a"} Device`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tracker && <span>{`Are you sure that you wish to unlink this device from ${data?.compoundName}?`}</span>}
        {!tracker && <CommonTable btnClick={onOperate} apiName="trackers" columns={columns} />}
      </Modal.Body>
      <Modal.Footer>
        {tracker && <Button variant="danger" onClick={() => onOperate()}>Unlink</Button>}
        <Button variant="secondary" onClick={() => setShow(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
    <Modal show={showWarning} onHide={() => setShowWarning(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure that you wish to link this device to {data?.compoundName}? This will unlink it
          from {showWarning?.assetCompoundName || showWarning?.personCompoundName || showWarning?.zoneCompoundName}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onOperate("relink", showWarning)}>Link</Button>
        <Button variant="secondary" onClick={() => setShowWarning(null)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;
}
  ;
export default DeviceTracker;