import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Dropdown } from "react-bootstrap";
import { Copy, Radio, Wifi, X } from "react-feather";
import api from "../../api";
import copy from "copy-to-clipboard";
import NotyfContext from "../../contexts/NotyfContext";

const DiagnosticZone = (props) => {
    const { readers, tags } = props;
    const intervalRef = useRef();
    const [deviceList, setDeviceList] = useState([]);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0);
    const [selectedDeviceName, setSelectedDeviceName] = useState("");
    const deviceData = useRef()
    const notify = useContext(NotyfContext);

    useEffect(() => {
        if (readers && tags && readers.constructor === Array && tags.constructor === Array) {
            const initialDeviceList = [];
            readers.forEach(element => {
                initialDeviceList.push({ ...element, deviceName: <><Wifi size={18} />&nbsp; {element.serial}</>, deviceType: "reader", data: [], rawData: [] });
            });
            tags.forEach(element => {
                initialDeviceList.push({ ...element, deviceName: <><Radio size={18} />&nbsp; {element.serial}</>, deviceType: "tag", data: [], rawData: [] });
            });
            if (initialDeviceList.length > 0) {
                setDeviceList(initialDeviceList);
                setSelectedDeviceIndex(0);
                setSelectedDeviceName(initialDeviceList[0].deviceName);
            }
        }
    }, [readers, tags]);

    useEffect(() => {
        if (deviceList && deviceList.constructor === Array && deviceList.length > 0) {
            intervalRef.current = setInterval(() => {
                getRawData();
            }, 5000);

            getRawData();

            return () => {
                clearInterval(intervalRef.current);
            };
        }
    }, [deviceList]);

    const getRawData = () => {
        if (deviceList && deviceList.constructor === Array && deviceList.length > 0) {
            let promiseArr = [];
            deviceList.forEach(item => {
                let url = `${item.deviceType}s/${item.id}/raw`;
                promiseArr.push(api.get(url));
            });
            Promise.all(promiseArr).then(res => {
                res.forEach((item, index) => {
                    var device = deviceList[index];

                    if (!device.data.includes(item.data) && item.data !== "") {
                        device.data.push(item.data);
                        device.rawData.push(<div key={item.data}>{item.data}</div>);
                    }
                })
            }).catch(error => console.log(error));
        }
    };

    const copyText = () => {
        if (!deviceData.current?.innerText) return
        if (copy(deviceData.current?.innerText)) {
            notify.open({
                type: "success",
                message: "Copied !"
            });
        }
    }

    const clearText = () => {
        var newDeviceList  = [];
        deviceList.forEach((device, index) => {
            if (index === selectedDeviceIndex)
            {
                device = {...device, data: [], rawData: []};
            }
            newDeviceList.push(device);

        });
        setDeviceList(newDeviceList);
    }

    return <>
        <Card className="dignostics-card shadow-none">
            <Card.Body className="p-0">
                <Card.Title>Diagnostic Data</Card.Title>
                <div className="d-flex justify-content-between align-items-center">
                    {deviceList && deviceList.length > 0 &&
                        <Dropdown className="mt-2 mb-2" >
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                                {selectedDeviceName}&nbsp;&nbsp;
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="light">
                                {deviceList.map((item, key) =>
                                    <Dropdown.Item
                                        key={key}
                                        onClick={
                                            () => {
                                                setSelectedDeviceIndex(key);
                                                setSelectedDeviceName(item.deviceName);
                                            }
                                        } >
                                        {item.deviceName}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
                {
                    deviceList && deviceList.length > 0 &&
                    deviceList[selectedDeviceIndex].rawData.length > 0 &&
                    <div>
                        <Button onClick={() => copyText()}><Copy size={14} />&nbsp;Copy to Clipboard</Button>
                        <Button onClick={() => clearText()} variant='success' className="ms-2"><X size={14} />&nbsp;Clear</Button>
                    </div>
                }
                <h5 className="mt-4 mb-3">{selectedDeviceName}</h5>
                <div ref={deviceData} className="device-raw-data">
                    <div className="tracker">
                        {
                            deviceList && deviceList.length > 0 &&
                            deviceList[selectedDeviceIndex].rawData.map((item, index) => <div key={index} className="d-flex w-100 break-all">
                                <span className="me-1">{index + 1}.</span>{item}
                            </div>)
                        }
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>;
};

export default DiagnosticZone;
