import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import { Helmet } from "react-helmet-async";
import RootTopBar from "../../components/navbar/RootTopBar";
import api from "../../api";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Disc, Grid, Package, Users } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeartbeat } from "@fortawesome/free-solid-svg-icons";
import LastLocation from "../../components/LastLocation";
import LastAttendance from "../../components/LastAttendance";
import moment from "moment-timezone";
import Diagnostics from "../../components/detail/Diagnostics";
import DeviceDetail from "../../components/detail/DeviceDetail";
import QRCodeWithDownload from "../../components/QRCodeWithDownload";

let interval;

const QrCodesEdit = () => {
    const location = useLocation()
    const { id } = queryString.parse(location.search);
    const [device, setDevice] = useState(null);
    const [thing, setThing] = useState(null);
    const [assetLoaded, setAssetLoaded] = useState(false);
    const [personLoaded, setPersonLoaded] = useState(false);
    const [zoneLoaded, setZoneLoaded] = useState(false);
    const [assetType, setAssetType] = useState(null);

    useEffect(() => {
        getDevice();
        interval && clearInterval(interval)
        interval = setInterval(() => {
            getDevice();
        }, 5000);
    }, [id]);

    useEffect(() => {
        return () => {
            clearInterval(interval)
        };
    }, []);

    const getDevice = () => {
        api.get(`qrcodes/${id}`).then(res => {
            setDevice(res.data);
            if (res.data.assetId) {
                getAsset(res.data.assetId);
                setAssetType('assets');
                setPersonLoaded(true);
            } else if (res.data.personId) {
                getPerson(res.data.personId);
                setAssetType('people');
                setAssetLoaded(true);
            } else if (res.data.zoneId) {
                getZone(res.data.zoneId);
                setAssetType('zones');
                setZoneLoaded(true);
            } else {
                setAssetLoaded(true);
                setPersonLoaded(true);
                setZoneLoaded(true);
            }
        });
    };

    const getAsset = (id) => {
        api.get(`assets/` + id)
            .then(res => {
                setThing(res.data);
                setAssetLoaded(true);
            });
    };

    const getPerson = (id) => {
        api.get(`people/` + id)
            .then(res => {
                setThing(res.data);
                setPersonLoaded(true);
            });
    };

    const getZone = (id) => {
        api.get(`zones/` + id)
            .then(res => {
                setThing(res.data);
                setZoneLoaded(true);
            });
    };

    return <>
        <Helmet defer={false} title={device?.serial || 'Tag'} />
        <RootTopBar data={device} />
        <Container fluid className="p-0">
            {device?.serial && <h1 className="h3 mb-3 d-flex align-items-center">
                <Grid size={18} />
                <span className="ms-2">{device.serial}</span>
            </h1>}
            {assetType && <h3 className="mb-3 h5">
                {assetType === 'assets' && <Package size={18} />}
                {assetType === 'people' && <Users size={18} />}
                {assetType === 'zones' && <Disc size={18} />}
                <NavLink to={{ pathname: `/${assetType}/edit`, search: `?id=${thing?.id}` }} className="ms-2">{thing?.compoundName}</NavLink>
            </h3>}
            <Row>
                <Col md="4" xl="3">
                    {device?.lastReport && <Card className="mb-0 border-bottom">
                        <Card.Body>
                            <FontAwesomeIcon icon={faHeartbeat} size={'sm'} />
                            <span className="ms-2">{moment(device?.lastReport).format("DD/MM/YYYY HH:mm:ss")}</span>
                        </Card.Body>
                    </Card>}
                    {thing?.lastPosition ? (<LastLocation profile={thing} />) : null}
                    {thing?.lastAttendance ? (<LastAttendance profile={thing} />) : null}
                    <Card className="mb-0 border-top">
                        <Card.Body>
                            {device && <QRCodeWithDownload value={`${device.serial}`} />}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="8" xl="9" className="mt-3 mt-sm-0">
                    <div className="tab">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="details">
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="details">Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="diagnostics">Raw Data</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="details">
                                    <DeviceDetail setThing={(e) => setDevice(e)} thing={device} assetType={assetType} type={'qrcodes'} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="diagnostics">
                                    {device !== null && assetLoaded && personLoaded && zoneLoaded && <Diagnostics tags={[device]} apps={[]} root trackers={[]} type={1} />}
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Col>
            </Row>
        </Container>

    </>;
}

export default QrCodesEdit;
