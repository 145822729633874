import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import api from "../../api";
import { getName } from "../../utils/staticMethods";

const NewObject = ({ showModal, closeModal, apiName, onSuccess }) => {

  const navigate = useNavigate();

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{`New ${getName(apiName)}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="shadow-none">
          <Card.Body>
            <Formik
              initialValues={{
                submit: false,
                primaryName: '',
                secondaryName: '',
                reference: ''
              }}
              validationSchema={Yup.object().shape({
                primaryName: Yup.string()
                  .max(255)
                  .matches(
                    /^[^#]*$/,
                    "'#' is a protected character used for hashtag searches and cannot be used here"
                  )
                  .required("Primary Name is required"),
                secondaryName: Yup.string().max(255).matches(
                  /^[^#]*$/,
                  "'#' is a protected character used for hashtag searches and cannot be used here"
                ),
                reference: Yup.string().max(255).matches(
                  /^[^#]*$/,
                  "'#' is a protected character used for hashtag searches and cannot be used here"
                ),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                  api.post(apiName, {
                    primaryName: values.primaryName,
                    secondaryName: values.secondaryName,
                    reference: values.reference
                  })
                    .then(res => {
                      if (onSuccess) {
                        onSuccess(res.data.id);
                      } else {
                        navigate(`/${apiName}/edit?id=${res.data.id}`);
                      }
                    })
                } catch (error) {
                  const message = error.message || "Something went wrong";
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Primary Name</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="primaryName"
                      value={values.primaryName}
                      isInvalid={Boolean(touched.primaryName && errors.primaryName)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.primaryName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.primaryName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Secondary Name</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="secondaryName"
                      value={values.secondaryName}
                      isInvalid={Boolean(touched.secondaryName && errors.secondaryName)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.secondaryName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.secondaryName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Reference</Form.Label>
                    <Form.Control
                      size="md"
                      type="text"
                      name="reference"
                      value={values.reference}
                      isInvalid={Boolean(touched.reference && errors.reference)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.reference && (
                      <Form.Control.Feedback type="invalid">
                        {errors.reference}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <div className="mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      size="md"
                      disabled={isSubmitting}
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default NewObject;