import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children, role }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (isInitialized && !isAuthenticated) {
    if (window.location) {
      localStorage.setItem('lastPage', window.location.pathname ?? '' + window.location.search ?? '');
    }

    return <Navigate to="/auth/sign-in" />;
  }

  // check the user role, make sure that different user types can only see their own menu
  if (user?.role !== 'Root' && isInitialized && isAuthenticated && role && !role.includes(user?.role)) {

    return <Navigate to="/" />;
  }

  if (user?.role === 'Root' && isInitialized && isAuthenticated && role && !role.includes(user?.role)) {
    var newLocation = "/customers";

    // Retrieve the last page from localStorage or sessionStorage
    const lastPage = localStorage.getItem('lastPage');
    if (lastPage) {
      // Optionally, remove the item from storage if it should only be used once
      localStorage.removeItem('lastPage');
      // Navigate back to the last page
      newLocation = lastPage;
    }

    return <Navigate to={`${newLocation}`} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
