import React, { useContext, useRef, useState } from "react";
import { Button, Card, Container, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import { Radio, Wifi } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";

const Device = ({ id, onGetList, type, data, onGetTagsList }) => {
  const [showReaderModal, setShowReader] = useState(false);
  const [curReader, setCurrentReader] = useState(false);
  const [showReaderLinkModal, setShowReaderLink] = useState(false);
  const [showWarningReader, setShowWarningReader] = useState(false);

  const [showTagModal, setShowTag] = useState(false);
  const [curTag, setCurrentTag] = useState(false);
  const [showTagLinkModal, setShowTagLink] = useState(false);
  const [showWarningTag, setShowWarningTag] = useState(false);

  const childRef = useRef();
  const tagsTableRef = useRef();
  const notify = useContext(NotyfContext);
  const { user } = useAuth();

  const columnsReader = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: cell => {
        if (user?.role === 'Root') {
          return <NavLink to={{ pathname: '/readers/edit', search: `?id=${cell.row.original?.id}` }}>{cell.value}</NavLink>
        } else {
          return <span>{cell.value}</span>
        }
      }
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Name",
      accessor: "deviceName",
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      returnParam: "id",
      show: user?.editReaders,
      action: [{ name: "Unlink Reader", color: "danger", disabled: (!user?.editReaders || user?.role === "Root") }]
    }
  ];

  const columnsTag = [
    {
      Header: "#Serial",
      accessor: "serial",
      Cell: cell => {
        if (user?.role === 'Root') {
          return <NavLink to={{ pathname: '/readers/edit', search: `?id=${cell.row.original?.id}` }}>{cell.value}</NavLink>
        } else {
          return <span>{cell.value}</span>
        }
      }
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Name",
      accessor: "deviceName",
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "id",
      type: "action",
      returnParam: "id",
      show: user?.editReaders,
      action: [{ name: "Unlink Tag", color: "danger", disabled: (!user?.editReaders || user?.role === "Root") }]
    }
  ];

  const columnsLinkReader = [
    {
      Header: "",
      accessor: "id",
      disableSortBy: true,
      Cell: cell => (
        <Button
          variant={cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "danger" : "success"}
          onClick={() => onOperateReader(cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "warn" : "edit", cell.value, cell.row.original)}>Link</Button>
      )
    },
    {
      Header: "#Serial",
      accessor: "serial"
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];

  const columnsLinkTag = [
    {
      Header: "",
      accessor: "id",
      disableSortBy: true,
      Cell: cell => (
        <Button
          variant={cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "danger" : "success"}
          onClick={() => onOperateTag(cell.row.original.assetId || cell.row.original.personId || cell.row.original.zoneId ? "warn" : "edit", cell.value, cell.row.original)}>Link</Button>
      )
    },
    {
      Header: "#Serial",
      accessor: "serial"
    },
    {
      Header: "Model",
      accessor: "model",
      disableSortBy: true,
    },
    {
      Header: "Last Seen",
      accessor: "lastReport",
      type: "dateFromNow"
    },
    {
      Header: "Linked",
      accessor: "customerId",
      type: "linkStatus",
      disableSortBy: true
    }
  ];

  const btnClickReader = (name, id) => {
    setCurrentReader(id);
    setShowReader(true);
  };

  const btnClickTag = (name, id) => {
    setCurrentTag(id);
    setShowTag(true);
  };

  const onSaveReader = () => {
    api.post(`readers/${curReader}/unlink/${id}`).then(() => {
      childRef.current.updateTable(1);
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      setShowReader(false);
    });
  };

  const onSaveTag = () => {
    api.post(`tags/${curTag}/unlink/${id}`).then(() => {
      tagsTableRef.current.updateTable(1);
      notify.open({
        type: "success",
        message: "Changes Saved"
      });
      setShowTag(false);
    });
  };

  const onOperateReader = (name, curId, data) => {
    if (name === "edit") {
      api.post(`readers/${curId}/link/${id}`).then(() => {
        childRef.current.updateTable(1);
        notify.open({
          type: "success",
          message: "Changes Saved"
        });
      });
    } else if (name === "warn") {
      setShowWarningReader(data);
    } else if (name === "relink") {
      api.post(`readers/${curId?.id}/unlink/${curId?.assetId || curId?.personId || curId?.zoneId}`)
        .then(res => {
          api.post(`readers/${curId?.id}/link/${id}`, { params: { parentId: id, id: curId } })
            .then(res => {
              notify.open({
                type: "success",
                message: "Changes Saved"
              });
              childRef.current.updateTable(1);
              setShowWarningReader(null);
            });
        });
    }
    setShowReaderLink(false);
  };

  const onOperateTag = (name, curId, data) => {
    if (name === "edit") {
      api.post(`tags/${curId}/link/${id}`).then(() => {
        tagsTableRef.current.updateTable(1);
        notify.open({
          type: "success",
          message: "Changes Saved"
        });
      });
    } else if (name === "warn") {
      setShowWarningTag(data);
    } else if (name === "relink") {
      api.post(`tags/${curId?.id}/unlink/${curId?.assetId || curId?.personId || curId?.zoneId}`)
        .then(res => {
          api.post(`tags/${curId?.id}/link/${id}`, { params: { parentId: id, id: curId } })
            .then(res => {
              notify.open({
                type: "success",
                message: "Changes Saved"
              });
              tagsTableRef.current.updateTable(1);
              setShowWarningTag(null);
            });
        });
    }
    setShowTagLink(false);
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0 m-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className=""><Wifi size={18} /> Readers</h4>
          {user?.editReaders && user?.role !== "Root" &&
            <Button onClick={() => {
              setShowReaderLink(true);
            }}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Link a Reader</Button>}
        </div>
        <Card className="bg-white p-0 shadow-none">
          <CommonTable btnClick={btnClickReader} onGetList={onGetList} ref={childRef} apiName={`zones/readers/${id}`}
            parentId={id} columns={columnsReader} />
        </Card>
      </Container>
      <hr />
      <Container fluid className="p-0 m-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4 className=""><Radio size={18} /> Tags</h4>
          {user?.editTags && user?.role !== "Root" &&
            <Button onClick={() => {
              setShowTagLink(true);
            }}><FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />Link a Tag</Button>}
        </div>
        <Card className="bg-white p-0 shadow-none">
          <CommonTable btnClick={btnClickTag} onGetList={onGetTagsList} ref={tagsTableRef} apiName={`zones/tags/${id}`}
            parentId={id} columns={columnsTag} />
        </Card>
      </Container>
      <hr />

      <Modal show={showReaderModal} onHide={() => setShowReader(false)}>
        <Modal.Header closeButton><Modal.Title>Unlink a Reader</Modal.Title></Modal.Header>
        <Modal.Body>
          <span>Are you sure you want to unlink this device ?</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => onSaveReader()}>Unlink</Button>
          <Button variant="secondary" onClick={() => {
            setShowReader(false);
            setCurrentReader(null);
          }}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showReaderLinkModal} size={"lg"} onHide={() => setShowReaderLink(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Link a Reader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonTable btnClick={onOperateReader} apiName="readers" columns={columnsLinkReader} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowReaderLink(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWarningReader} onHide={() => setShowWarningReader(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure that you wish to link this device to {data?.compoundName}? This will unlink it
            from {showWarningReader?.assetCompoundName || showWarningReader?.personCompoundName || showWarningReader?.zoneCompoundName}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onOperateReader("relink", showWarningReader)}>Link</Button>
          <Button variant="secondary" onClick={() => setShowWarningReader(null)}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTagModal} onHide={() => setShowTag(false)}>
        <Modal.Header closeButton><Modal.Title>Unlink a Tag</Modal.Title></Modal.Header>
        <Modal.Body>
          <span>Are you sure you want to unlink this device ?</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => onSaveTag()}>Unlink</Button>
          <Button variant="secondary" onClick={() => {
            setShowTag(false);
            setCurrentTag(null);
          }}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTagLinkModal} size={"lg"} onHide={() => setShowTagLink(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Link a Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonTable btnClick={onOperateTag} apiName="tags" columns={columnsLinkTag} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTagLink(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWarningTag} onHide={() => setShowWarningTag(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure that you wish to link this device to {data?.compoundName}? This will unlink it
            from {showWarningTag?.assetCompoundName || showWarningTag?.personCompoundName || showWarningTag?.zoneCompoundName}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onOperateTag("relink", showWarningTag)}>Link</Button>
          <Button variant="secondary" onClick={() => setShowWarningTag(null)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default Device;